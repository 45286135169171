import type { NullableRangeDto, RangeDto } from '@swipe4work/api-client-fetch'
import dayjs from 'dayjs'
import { useToast } from 'vue-toastification'

export function toPercentage(fraction: number) {
  return `${Math.round(fraction * 100)}%`
}

export function range(range: RangeDto | undefined) {
  if (range === undefined) {
    return ''
  }

  return `${range.lower} - ${range.upper}`
}

export function nullableRange(range: NullableRangeDto | null | undefined) {
  if (range == null) {
    return ''
  }

  if (!range.upper) {
    return `${range.lower}+`
  }

  return `${range.lower} - ${range.upper}`
}

export function showPhoneNumber(phoneNumber: string) {
  // pattern for +31 X XXX XXX XX
  const pattern = /^(\+31)(6)(\d{3})(\d{3})(\d{2})$/

  // Check if the phone number matches the pattern
  if (pattern.test(phoneNumber)) {
    // Use the pattern to match and replace the phone number
    return phoneNumber.replace(pattern, '06 $3 $4 $5')
  } else {
    // Return the original phone number if it doesn't match the format
    return phoneNumber
  }
}

/**
 * Parse and show validation errors as generated by the API.
 * If no validation errors could be parsed, a generic error is shown.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function showApiValidationErrors(summary: string, err: any) {
  const toast = useToast()
  try {
    const response = await err.response.json()
    if (response?.errors == null) {
      // show generic toast if no errors are present
      showGenericError(summary, err)
    }

    for (const [key, value] of Object.entries(response?.errors)) {
      // TODO: render key and value
      toast.error(`${key}: ${value}`)
    }
  } catch (e) {
    toast.error(summary)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function showGenericError(summary: string, err: any) {
  const toast = useToast()
  // TODO: render err.message
  toast.error(summary)
}

/**
 * Display the time when the date is today, else display the date and time.
 */
export function timeOrDateTime(date: Date) {
  const t = useI18n()
  const parsedDate = dayjs(date)
  if (parsedDate.format('DD') === dayjs().format('DD')) {
    return parsedDate.format('HH:mm')
  }

  if (parsedDate.format('DD') === dayjs().add(-1, 'd').format('DD')) {
    return `${t.t('yesterday')}, ${parsedDate.format('HH:mm')}`
  }

  return parsedDate.format('DD-MM, HH:mm')
}

/**
 * Display the time when the date is today or yesterday, else display the date.
 */
export function timeOrDate(date: Date | undefined) {
  if (!date) {
    return ''
  }

  const t = useI18n()
  const parsedDate = dayjs(date)
  const now = dayjs()
  if (parsedDate.isSame(now, 'day')) {
    return parsedDate.format('HH:mm')
  }

  const yesterday = now.add(-1, 'd')
  if (parsedDate.isSame(yesterday, 'day')) {
    return `${t.t('yesterday')}, ${parsedDate.format('HH:mm')}`
  }

  return parsedDate.format('DD-MM-YYYY')
}

export function date(date: Date) {
  return dayjs(date).format('DD-MM-YYYY')
}

export function dateTime(date: Date | null | undefined) {
  if (!date) {
    return ''
  }

  return dayjs(date).format('DD-MM-YYYY HH:mm')
}

export function yearMonthRange(start: Date, end: Date | undefined | null) {
  const t = useI18n()
  if (!end) {
    return `${dayjs(start).format('MM-YYYY')} - ${t.t('present')}`
  }

  return `${dayjs(start).format('MM-YYYY')} - ${dayjs(end).format('MM-YYYY')}`
}

export function yearRange(start: number, end: number | undefined | null) {
  const t = useI18n()
  if (!end) {
    return `${start} - ${t.t('present')}`
  }

  return `${start} - ${end}`
}

/**
 * Display the amount of years and months between a start and end
 * @returns
 */
export function yearMonthDuration(start: Date, end: Date | undefined | null) {
  const t = useI18n()
  const from = dayjs(start)
  let till = dayjs(end)
  if (!till) {
    till = dayjs()
  } // set till to now

  const yearDiff = till.diff(from, 'year')
  const monthDiff = till.diff(from, 'month') % 12
  if (yearDiff >= 5 || monthDiff === 0) {
    return `${yearDiff} ${t.t('year').toLocaleLowerCase()}`
  }

  if (yearDiff === 0) {
    return `${monthDiff} ${t.t('month', monthDiff).toLocaleLowerCase()}`
  }

  return `${yearDiff} ${t.t('year').toLocaleLowerCase()}, ${monthDiff} ${t.t('month', monthDiff).toLocaleLowerCase()}`
}
