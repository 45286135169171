/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ConferenceToCompanyDto interface.
 */
export function instanceOfConferenceToCompanyDto(value) {
    if (!('conferenceId' in value) || value['conferenceId'] === undefined)
        return false;
    if (!('conferenceFrom' in value) || value['conferenceFrom'] === undefined)
        return false;
    if (!('conferenceTo' in value) || value['conferenceTo'] === undefined)
        return false;
    if (!('companiesVisibleFrom' in value) || value['companiesVisibleFrom'] === undefined)
        return false;
    if (!('companiesVisibleTo' in value) || value['companiesVisibleTo'] === undefined)
        return false;
    if (!('standNumber' in value) || value['standNumber'] === undefined)
        return false;
    return true;
}
export function ConferenceToCompanyDtoFromJSON(json) {
    return ConferenceToCompanyDtoFromJSONTyped(json, false);
}
export function ConferenceToCompanyDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'conferenceId': json['conferenceId'],
        'conferenceFrom': (new Date(json['conferenceFrom'])),
        'conferenceTo': (new Date(json['conferenceTo'])),
        'companiesVisibleFrom': (new Date(json['companiesVisibleFrom'])),
        'companiesVisibleTo': (new Date(json['companiesVisibleTo'])),
        'conferenceArea': json['conferenceArea'] == null ? undefined : json['conferenceArea'],
        'standNumber': json['standNumber'],
    };
}
export function ConferenceToCompanyDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'conferenceId': value['conferenceId'],
        'conferenceFrom': ((value['conferenceFrom']).toISOString().substring(0, 10)),
        'conferenceTo': ((value['conferenceTo']).toISOString().substring(0, 10)),
        'companiesVisibleFrom': ((value['companiesVisibleFrom']).toISOString().substring(0, 10)),
        'companiesVisibleTo': ((value['companiesVisibleTo']).toISOString().substring(0, 10)),
        'conferenceArea': value['conferenceArea'],
        'standNumber': value['standNumber'],
    };
}
