<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useJobStore } from './jobs/job-store'
import { useAuthStore, useDashboardStore } from '@/store'
import { useTopbarStore } from '@/components/app/topbar-store'
import { useCompanyStore } from '@/store/company'

definePage({
  meta: {
    permissionResource: 'Dashboard',
  },
})

const t = useI18n()
const dashboardStore = useDashboardStore()
const authStore = useAuthStore()
const { isConferenceUser } = storeToRefs(authStore)

const { companyComplete } = storeToRefs(useCompanyStore())
const { hasJobs } = storeToRefs(useJobStore())

const showIntroductionCard = computed(() => !companyComplete.value || !hasJobs.value)

const { statistics } = storeToRefs(dashboardStore)
const topbarStore = useTopbarStore()
topbarStore.setAndClear({
  breadcrumbs: [{ label: t.t('routes.dashboard') }],
})
</script>

<template>
  <!-- only show introduction for conference user. In the future we can easily show it for all users. -->
  <IntroductionCard v-if="isConferenceUser && showIntroductionCard" :company-complete="companyComplete" :has-jobs="hasJobs" />
  <Dashboard v-else-if="statistics" :statistics="statistics" />
  <div v-else>
    <Skeleton width="33#" height="10rem" />
  </div>
</template>
